import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UserClient } from '../services/forecast-api-client.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormulationrequestService } from '../services/formulationrequest.service';
import { catchError, concatMap, switchMap, tap, toArray } from 'rxjs/operators';
import { Observable, forkJoin, from, throwError } from 'rxjs';
import { RawMaterialCompositionModel } from '../models/rawMaterialComposition.model';
import { ForecastItemModel } from '../models/forecast-item-forRawmaterial.model';
import { MessageService } from 'primeng/api';
import { RawMaterialDelegateDesignationModel } from '../models/rawMaterialDelegateDesignation.model';
import { AddressModel } from '../models/address.model';
import { trigger, state, style, animate, transition, keyframes, AnimationEvent } from '@angular/animations';


@Component({
  selector: 'app-formulation-raw-material',
  templateUrl: './formulation-raw-material.component.html',
  styleUrls: ['./formulation-raw-material.component.scss'],
  animations: [
    trigger('selectedAddressAnimation', [
      state('selected', style({})),
      transition('initial => selected', animate('1000ms', keyframes([
        style({ backgroundColor: 'white', offset: 0 }),
        style({ backgroundColor: '#0080002e', transform: 'scale(0.1)', offset: 0.5 }),
        style({ backgroundColor: '#0080002e', transform: 'scale(1)', offset: 1 })
      ])))
    ])
  ]
})
export class FormulationRawMaterialComponent implements OnInit {
  @ViewChild('inventoryCheck') inventoryCheck;
  formulationRequestInfo: {
    programNumber: string;
    factsId: string;
    productionOwner: string;
    productionOwnerId: string;
    productionLocation: string;
    productionLocationId: string;
    dateNeeded: Date;
    quantityForecasted: number;
    materialId: number;
    userId: number;
    reach: boolean;
  };
  filteredUsers: any[];
  selectedDelegate: any;
  quantityAdjustment: number = 0;
  submittedVal: string = "Submitted";
  totalQuantity: number;
  forecastType: string;
  rawMaterials = [];
  selectedRawMaterials = [];
  expiredAcceptableOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ];
  reachApprovedOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ];
  isGLPOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ];
  isDisplayformulationRawMaterialRow4: boolean = false;
  public forecastItem: ForecastItemModel;
  forecastItemIds: number[];
  rawMaterialCompositions: RawMaterialCompositionModel[] = [];
  forecastItemList: ForecastItemModel[] = [];
  forecastItemDestinationIdList: number[] = [];


  // Select Shipping Address variables
  AddressSelectionState: string = 'initial'; /// To track if the new address is selected
  selectedRecipient: any;
  addressList: AddressModel[] = [];
  selectedShippingAddress: AddressModel = {
    AddressId: 0,
    AddressLine1: '',
    AddressLine2: '',
    AddressLine3: '',
    PostalCode: '',
    City: '',
    State: '',
    CountryId: 0,
    OrgName: ''
  };
  isShippingAddressSaved: boolean = false;

  // Validate Active Ingredients & Formulation Features related variables
  currentRawMaterial: any;
  isAllSelectedGLPValid: boolean = true;
  isCOREMaterialLotSearchVisible: boolean = false;
  isFormulationMaterialLotSearchVisible: boolean = false;
  activeIngredientName: string;
  formulationName: string;
  searchText: string;
  formulationValidationAiC: string;
  formulationValidationConcept: string;
  formulationValidationComposition: string;
  formulationValidationLotNumber: string;

  isAddItemDialogVisible: boolean = false;
  isSearchItemResultVisible: boolean = false;
  materialTypeId: string = "1";
  isAddItemFormulation: boolean = false;
  addItemSearchText: string;
  addFormulationValidationAiC: string;
  addFormulationValidationConcept: string;
  addFormulationValidationComposition: string;
  addFormulationValidationLotNumber: string;
  searchItemResult: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userClient: UserClient,
    private renderer: Renderer2,
    private formulationService: FormulationrequestService,
    public messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.updateDatatableWrappersStyle();

    this.route.queryParams.subscribe(params => {
      this.formulationRequestInfo = {
        programNumber: params['programNumber'],
        factsId: params['factsId'],
        productionOwner: "",
        productionOwnerId: params['productionOwnerId'],
        productionLocation: "",
        productionLocationId: params['productionLocationId'],
        dateNeeded: params['dateNeeded'],
        quantityForecasted: params['quantityForecasted'],
        materialId: params['materialId'],
        userId: params['userId'],
        reach: (params['reach'] == "true")
      };

      this.populateProductionOwner(params['productionOwnerId']);
      this.populateProductionLocation(params['productionLocationId']);

      this.totalQuantity = params['quantityForecasted'];
      this.forecastType = params['forecastType'];
      this.populateRawMaterials(this.formulationRequestInfo.materialId).subscribe();
    });
  }

  updateDatatableWrappersStyle() {
    const elements = document.querySelectorAll('.p-datatable-wrapper');
    if (elements) {
      elements.forEach((element: any) => {
        this.renderer.setStyle(element, 'overflow', 'visible');
      });
    }
  }

  filterUsers(event: any) {
    let query = event.query;
    this.userClient.getMatchingUsers(query).subscribe(data => {
      this.filteredUsers = data;
      this.filteredUsers.forEach(user => {
        user.display = `${user.fullName} (${user.email})`;
      });
    });
  }

  onSelectDelegate(event: any) {
    if (event) {
      this.selectedDelegate = {
        ...event,
        display: event.fullName
      };
    }
  }

  calculateQuantity() {
    this.calculateTotalQuantity();
    this.rawMaterials.forEach(component => {
      component.quantity = this.calculateWeightQuantity(component.weigthPercentage);
    })
    this.validateComponentsTotalQuantity();
  }

  validateComponentsTotalQuantity() {
    let totalQuantityOfComponents = 0;
    this.rawMaterials.forEach(component => {
      totalQuantityOfComponents += component.quantity;
    })

    console.log("totalQuantity", this.totalQuantity);
    console.log("Total quantity of components", totalQuantityOfComponents.toFixed(2));
  }

  calculateTotalQuantity() {
    const result = parseFloat((Number(this.formulationRequestInfo.quantityForecasted) + Number(this.quantityAdjustment)).toFixed(2));
    this.totalQuantity = Math.max(result, 0);
  }

  calculateWeightQuantity(weightPercentage: number) {
    const decimalPercentage = weightPercentage / 100;
    const result = parseFloat((Number(this.totalQuantity) * decimalPercentage).toFixed(3));

    return result;
  }

  populateProductionOwner(id: number | null) {
    if (id != null) {
      this.userClient.getUser(id).subscribe(data => {
        this.formulationRequestInfo.productionOwner = data.fullName;
      });
    }
  }

  populateProductionLocation(id: number | null) {
    if (id != null) {
      this.formulationService.getProductionLocationById(id).subscribe(data => {
        this.formulationRequestInfo.productionLocation = data.productionLocationName;
      });
    }
  }

  getSpecialControlCompositionById(id: number) {
    return this.formulationService.getSpecialControlCompositionById(id).pipe(
      tap(data => {
        console.log("SpecialControlComposition", data);

        var components = data.components;
        if (components.length > 0) {
          components.forEach(component => {
            const rawMaterial = {
              materialId: component.materialId,
              materialName: component.tradeName,
              isGLP: { label: 'No', value: false },
              isGLPValidated: false,
              lotNumber: null,
              weigthPercentage: component.weightPct,
              quantity: this.calculateWeightQuantity(component.weightPct),
              expiredAcceptable: { label: 'Yes', value: true },
              reachApproved: this.formulationRequestInfo.reach ? { label: 'Yes', value: true } : { label: 'No', value: false },
              availability: true,
              availableQuantity: 'Available: 300 Litres',
              repo: component.repo,
              alternates: [],
              selectedRawMaterial: {}
            };

            if (component.alternates && component.alternates.length > 0) {
              rawMaterial.alternates.push({
                label: `${rawMaterial.materialName} - (Primary)`,
                materialId: rawMaterial.materialId,
                materialName: rawMaterial.materialName
              });

              component.alternates.forEach(alternate => {
                let qualification: string = "";

                if (alternate.qualified === null) {
                  qualification = "Not Determined";
                } else if (alternate.qualified === true) {
                  qualification = "Qualified";
                } else if (alternate.qualified === false) {
                  qualification = "Not Qualified";
                }

                rawMaterial.alternates.push({
                  label: `${alternate.alternateCoformulantTradeName} - (${qualification})`,
                  materialId: alternate.materialId,
                  materialName: alternate.alternateCoformulantTradeName
                });
              });
            }

            this.rawMaterials.push(rawMaterial);
          })
        }
        console.log("SpecialControlComposition after processed", this.rawMaterials);
      })
    );
  }

  populateMUCs(data: any) {
    var mucs = data.muCs;
    if (mucs.length > 0) {
      console.log("mucs", mucs);
      mucs.forEach(muc => {
        const rawMaterial = {
          materialId: muc.mucCompositionId,
          materialName: muc.muc.compositionName,
          isFormulation: true,
          isGLP: { label: 'No', value: false },
          isGLPValidated: false,
          isGLPColumnEnabled: true,
          lotNumber: null,
          weigthPercentage: muc.weightPct,
          quantity: this.calculateWeightQuantity(muc.weightPct),
          expiredAcceptable: { label: 'Yes', value: true },
          reachApproved: this.formulationRequestInfo.reach ? { label: 'Yes', value: true } : { label: 'No', value: false },
          availability: true,
          repo: muc.repo
        };
        this.rawMaterials.push(rawMaterial);
      })
    }
  }

  populateFormulationConcepts(data: any) {
    var formulationConcept = data.formulationConcept;
    if (formulationConcept) {
      console.log("formulationConcept", formulationConcept);
      var formulationConceptActives = formulationConcept.formulationConceptActives;
      formulationConceptActives.forEach(formulationConceptActive => {
        let weigthPct = this.calculateWeightPercentageForFormulationConcept(formulationConceptActive.loading, data.density);

        const rawMaterial = {
          materialId: formulationConceptActive.active.materialId,
          materialName: formulationConceptActive.activeName,
          isGLP: { label: 'No', value: false },
          isGLPValidated: false,
          isGLPColumnEnabled: true,
          lotNumber: null,
          loading: formulationConceptActive.loading,
          weigthPercentage: weigthPct,
          quantity: this.calculateWeightQuantity(weigthPct),
          expiredAcceptable: { label: 'Yes', value: true },
          reachApproved: this.formulationRequestInfo.reach ? { label: 'Yes', value: true } : { label: 'No', value: false },
          availability: true,
          repo: formulationConceptActive.active.repo,
        };
        this.rawMaterials.push(rawMaterial);
      })
    }
  }

  calculateWeightPercentageForFormulationConcept(load: number, density: number) {
    let result = load / density * 100;
    return parseFloat(result.toFixed(4));
  }

  getConfidentialLotByLotName(lotName: string) {
    return this.formulationService.getConfidentialLotByLotName(lotName).pipe(
      tap(data => {
        console.log("ConfidentialLot", data);

        if (data.length > 0) {
          var actives = data[0].actives;
          actives.forEach(active => {
            this.rawMaterials.push({
              materialId: active.materialId,
              materialName: active.activeName,
              isGLP: { label: 'No', value: false },
              isGLPValidated: false,
              isGLPColumnEnabled: true,
              lotNumber: null,
              weigthPercentage: active.weightPct,
              quantity: this.calculateWeightQuantity(active.weightPct),
              expiredAcceptable: { label: 'Yes', value: true },
              reachApproved: this.formulationRequestInfo.reach ? { label: 'Yes', value: true } : { label: 'No', value: false },
              availability: true,
              availableQuantity: 'Available: 300 Litres',
              repo: active.repo
            })
          })
        }
      })
    );
  }

  populateRawMaterials(id: number) {
    this.rawMaterials = [];
    this.isDisplayformulationRawMaterialRow4 = false;

    return this.getSpecialControlCompositionById(id).pipe(
      switchMap(data => {
        if (data.muCs && data.muCs.length > 0) {
          this.populateMUCs(data);
          this.populateFormulationConcepts(data);
        }

        if (data.lots.length > 0) {
          var lotNumber = data.lots[0].lotNumber;
          return this.getConfidentialLotByLotName(lotNumber).pipe(
            tap(() => {
              this.isDisplayformulationRawMaterialRow4 = true;
            })
          )
        } else {
          this.isDisplayformulationRawMaterialRow4 = true;
          return null;
        }
      })
    );
  }

  isDelegateValid(): boolean {
    if (!this.selectedDelegate.id) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: `Please ensure the delegate input box is empty or select an option from the dropdown list.` });
      return false;
    }

    if (this.formulationRequestInfo.productionOwnerId == this.selectedDelegate.id) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: `Production owner cannot be assigned as a delegate` });
      return false;
    }

    return true;
  }

  submit() {
    this.updateSelectedRawMaterials();

    if (this.selectedDelegate) {
      if (!this.isDelegateValid()) {
        return;
      }
      this.saveDelegate();
    }

    this.updateShippingAddress(this.formulationRequestInfo.programNumber, this.selectedShippingAddress.AddressId, this.selectedRecipient.email);
    const rawMaterials = this.selectedRawMaterials;

    this.populateForecastItemList(this.formulationRequestInfo, rawMaterials);

    this.createForecastItemObservable(this.forecastItemList).pipe(
      concatMap(() =>
        this.populateForecastItemDestinationIdListObservable(this.forecastItemIds).pipe(toArray())
      ),
      concatMap(() => {
        this.createRawMaterialCompositions(this.formulationRequestInfo, rawMaterials);
        return forkJoin([
          this.saveRawMaterialCompositionObservable(this.rawMaterialCompositions),
          this.updateStatusObservable(this.formulationRequestInfo.programNumber, null, null, this.submittedVal)
        ]);
      })
    ).subscribe(
      () => {
        this.router.navigate(['formulation-requests']);
      },
      (error: any) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: `${error}` });
      }
    );
  }

  updateStatusObservable(programNumber: any, productionLocationId: any, productionOwnerId: any, status: any): Observable<any> {
    return this.formulationService.updateStatus(programNumber, null, null, status).pipe(
      tap(() => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Update status successfully!' });
      }),
      catchError((error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to update status!' });
        return throwError(error);
      })
    );
  }

  createForecastItemObservable(forecastItemList): Observable<any[]> {
    return this.formulationService.createForecastItem(forecastItemList).pipe(
      tap((createdForecastItems: any[]) => {
        this.forecastItemIds = createdForecastItems.map(item => item.id);
      }),
      catchError((error: any) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: `Failed to create forecast items! ${error}` });
        return throwError(error);
      })
    );
  }

  populateForecastItemList(formulationRequestInfo, rawMaterials) {
    rawMaterials.forEach(rawMaterial => {
      let forecastItem: ForecastItemModel = {
        isReachCompliant: rawMaterial.reachApproved.value,
        productName: rawMaterial.materialName,
        materialId: rawMaterial.materialId,
        repoName: rawMaterial.repo,
        isGlp: rawMaterial.isGLP.value,
        stateId: 1,
        forecastingUserId: formulationRequestInfo.userId,
        forecastTypeId: rawMaterial.isGLP.value ? 2 : 1,

        forecastItemDestination: [{
          destinationCountryId: parseInt(formulationRequestInfo.productionLocationId),
          quantity: rawMaterial.quantity,
          quantityUomName: 'kg',
          dateNeeded: formulationRequestInfo.dateNeeded,
          forecastTypeId: rawMaterial.isGLP.value ? 2 : 1
        }],
      };

      this.forecastItemList.push(forecastItem);
    });
  }

  populateForecastItemDestinationIdListObservable(forecastItemIds: number[]): Observable<any> {
    return from(forecastItemIds).pipe(
      concatMap(forecastItemId =>
        this.formulationService.getForecastItemDestinationByForecastItemId(forecastItemId)
      ),
      tap(response => {
        this.forecastItemDestinationIdList.push(response[0].id);
      }),
      catchError(error => {
        console.error(error);
        return throwError(error);
      })
    );
  }

  createRawMaterialCompositions(formulationRequestInfo, rawMaterials) {
    let rawMaterialCompositions: RawMaterialCompositionModel[] = [];

    rawMaterials.forEach((rawMaterial, index) => {
      let forecastItemId = this.forecastItemIds[index];
      let forecastItemDestinationId = this.forecastItemDestinationIdList[index];
      let materialId: string;
      let materialName: string;
      if (rawMaterial.selectedRawMaterial && rawMaterial.selectedRawMaterial.materialId) {
        materialId = rawMaterial.selectedRawMaterial.materialId;
        materialName = rawMaterial.selectedRawMaterial.materialName;
      } else {
        materialId = rawMaterial.materialId;
        materialName = rawMaterial.materialName;
      }

      let rawMaterialComposition: RawMaterialCompositionModel = {
        programNumber: formulationRequestInfo.programNumber,
        forecastItemId: forecastItemId,
        materialId: materialId,
        materialName: materialName,
        procurementStatus: 2,
        quantityRequested: rawMaterial.quantity,
        orderQuantityUomId: 3,
        expiredAcceptable: rawMaterial.expiredAcceptable.value,
        reachApproved: rawMaterial.reachApproved.value,
        qualified: '',
        excelInclusion: false,
        excelDate: null,
        createDate: null,
        createdByUserId: formulationRequestInfo.userId,
        modifiedDate: null,
        modifiedByUserId: formulationRequestInfo.userId,
        isGLP: rawMaterial.isGLP.value,
        lotNumber: rawMaterial.lotNumber,
        targetForecastItemDestinationId: forecastItemDestinationId,
        repoName: rawMaterial.repo
      };

      rawMaterialCompositions.push(rawMaterialComposition);
    })

    this.rawMaterialCompositions = rawMaterialCompositions;
  }

  saveRawMaterialCompositionObservable(rawMaterialCompositions): Observable<any> {
    return this.formulationService.saveRawMaterialComposition(rawMaterialCompositions).pipe(
      tap(() => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Save raw material components successfully!' });
      }),
      catchError((error: any) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: `Failed to save raw material components! ${error}` });
        return throwError(error);
      })
    );
  }

  saveDelegate() {
    let body: RawMaterialDelegateDesignationModel = {
      createdByUserId: this.formulationRequestInfo.userId,
      delegateId: this.selectedDelegate.id,
      programNumber: this.formulationRequestInfo.programNumber,
    };

    this.formulationService.saveDelegateDesignation(body).subscribe(
      () => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Save delegate successfully!' });
      },
      (error: any) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: `Failed to save delegate! ${error}` });
      }
    );
  }

  recipientOnSelect(event: any) {
    if (event) {
      this.selectedRecipient = {
        ...event,
        display: event.fullName
      };
    }

    this.validateSelectedRecipient();
  }

  validateSelectedRecipient() {
    this.formulationService.getRecipientAddresses(this.selectedRecipient.email).subscribe(
      res => {
        if (res.length > 0) {
          this.getAddressList(res);
        } else {
          this.clearAddressList();
          this.messageService.add({ severity: 'error', summary: "Selected Recipient profile does not exist.", detail: '' });
        }
      },
      error => {
        this.messageService.add({ severity: 'error', summary: `There is an erorr while retrieving addresses! ${error}`, detail: '' });
      }
    )
  }

  clearAddressList() {
    this.selectedShippingAddress = null;
    this.addressList = [];
  }

  getAddressList(addresses: AddressModel[]) {
    this.addressList = [];
    addresses.forEach((value) => {
      var addressModel = JSON.parse(JSON.stringify(value));
      addressModel.PersonFullName = this.selectedRecipient.fullName;
      if (addressModel.OrgName != null && addressModel.OrgName != undefined) {
        this.addressList.push(addressModel);
      }
    });

    this.addressList.forEach(address => {
      address.IsDefaultAddress ? this.selectedShippingAddress = address : undefined;
    })
  }

  shipmentAddressOnClick(address) {
    this.selectedShippingAddress = address;
    this.AddressSelectionState = 'selected';
  }

  handleTransitionDone(event: AnimationEvent): void {
    if (this.AddressSelectionState !== "initial") {
      this.AddressSelectionState = "initial";
    }
  }

  saveShippingAddress() {
    this.isShippingAddressSaved = true;
  }

  editShippingAddress() {
    this.isShippingAddressSaved = false;
  }

  updateShippingAddress(programNumber: string, addressId: number, recipientEmail: string) {
    this.formulationService.updateShippingAddress(programNumber, addressId, recipientEmail).subscribe(
      (response) => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Save shipping address successfully!' });
      },
      (error: any) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: `Failed to save shipping address! ${error}` });
      }
    );
  }

  isGLPOnClick(rawMaterial: any) {
    this.currentRawMaterial = rawMaterial;

    if (rawMaterial.isGLP.value) {
      if (rawMaterial.isFormulation) {
        this.formulationName = rawMaterial.materialName;
        this.isFormulationMaterialLotSearchVisible = true;
      } else {
        if (rawMaterial.selectedRawMaterial && Object.keys(rawMaterial.selectedRawMaterial).length > 0) {
          this.activeIngredientName = rawMaterial.selectedRawMaterial.materialName;
        } else {
          this.activeIngredientName = rawMaterial.materialName;
        }
        this.isCOREMaterialLotSearchVisible = true;
      }
    } else {
      rawMaterial.isGLPValidated = false;
      rawMaterial.lotNumber = null;
    }

    this.checkIsAllSelectedGLPValid();
  }

  validateActiveIngredient() {
    let lotId = this.searchText;
    this.formulationService.getCompoundLotsByLotId(lotId).subscribe(
      (response) => {
        console.log("validateActiveIngredient response", response)
        if (response && response.length > 0) {
          this.currentRawMaterial.lotNumber = lotId;
          this.currentRawMaterial.isGLPValidated = true;

          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Material Lot ID is valid!' });
          this.searchText = "";
          this.isCOREMaterialLotSearchVisible = false;
        } else {
          this.currentRawMaterial.lotNumber = null;
          this.currentRawMaterial.isGLPValidated = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: `Material Lot ID is not validated. Please re-enter the correct Lot ID` });
          this.isCOREMaterialLotSearchVisible = true;
        }

        const index = this.rawMaterials.findIndex(rm => rm.materialId === this.currentRawMaterial.materialId);
        this.rawMaterials[index] = { ...this.currentRawMaterial };
        this.updateSelectedRawMaterials();
      },
      (error: any) => {
        this.currentRawMaterial.lotNumber = null;
        this.currentRawMaterial.isGLPValidated = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: `Failed to validate active ingredient!` });
      }
    )
  }

  validateFormulation() {
    let formulationLotNumber = this.formulationValidationAiC + "-" + this.formulationValidationConcept + "-" + this.formulationValidationComposition + "-" + this.formulationValidationLotNumber;
    console.log("formulationLotNumber", formulationLotNumber);
    this.formulationService.getConfidentialLotByLotName(formulationLotNumber).subscribe(
      (response) => {
        console.log("validateFormulation response", response)
        if (response && response.length > 0) {
          this.currentRawMaterial.lotNumber = formulationLotNumber;
          this.currentRawMaterial.isGLPValidated = true;

          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Material Lot Number is valid!' });
          this.resetFormulationDialogValues();
          this.isFormulationMaterialLotSearchVisible = false;
        } else {
          this.currentRawMaterial.lotNumber = null;
          this.currentRawMaterial.isGLPValidated = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: `Material Lot Number is not validated. Please re-enter the correct Lot ID` });
          this.isFormulationMaterialLotSearchVisible = true;
        }

        const index = this.rawMaterials.findIndex(rm => rm.materialId === this.currentRawMaterial.materialId);
        this.rawMaterials[index] = { ...this.currentRawMaterial };
        this.updateSelectedRawMaterials();
      },
      (error: any) => {
        this.currentRawMaterial.lotNumber = null;
        this.currentRawMaterial.isGLPValidated = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: `Failed to validate active ingredient!` });
      }
    )
  }

  onRawMaterialCheckboxClicked(event: any) {
    this.checkIsAllSelectedGLPValid();
  }

  activeIngredientDialogOnHide() {
    this.searchText = "";
    this.checkIsAllSelectedGLPValid();
  }

  formulationDialogOnHide() {
    this.resetFormulationDialogValues();
    this.checkIsAllSelectedGLPValid();
  }

  resetFormulationDialogValues() {
    this.formulationValidationAiC = "";
    this.formulationValidationConcept = "";
    this.formulationValidationComposition = "";
    this.formulationValidationLotNumber = "";
  }

  checkIsAllSelectedGLPValid() {
    this.updateSelectedRawMaterials();
    this.isAllSelectedGLPValid = !this.selectedRawMaterials.some(selectedRawMaterial =>
      selectedRawMaterial.isGLP.value && !selectedRawMaterial.isGLPValidated
    )
  }

  // Update this.selectedRawMaterials if the properties of any selected raw materials change
  updateSelectedRawMaterials() {
    this.rawMaterials.forEach(rawMaterial => {
      const index = this.selectedRawMaterials.findIndex(rm => rm.materialId === rawMaterial.materialId);
      if (index > -1) {
        this.selectedRawMaterials[index] = { ...rawMaterial };
      }
    })
  }

  addItem() {
    this.isAddItemDialogVisible = true;
  }

  onNewItemTypeChange() {
    this.resetAddItemInputBox();

    switch (this.materialTypeId) {
      case '1': {
        this.isAddItemFormulation = false;
        break;
      }
      case '2': {
        this.isAddItemFormulation = true;
        break;
      }
      case '3': {
        this.isAddItemFormulation = false;
        break;
      }
      case '4': {
        this.isAddItemFormulation = false;
        break;
      }
    }
  }

  addItemDialogOnHide() {
    this.isAddItemDialogVisible = false;
    this.searchItemResult = [];
    this.resetAddItemInputBox();
  }

  resetAddItemInputBox() {
    this.addItemSearchText = "";
    this.addFormulationValidationAiC = "";
    this.addFormulationValidationConcept = "";
    this.addFormulationValidationComposition = "";
    this.isSearchItemResultVisible = false;
  }

  updateSearchItemResult(items: any) {
    this.searchItemResult = [];

    if (items && items.length > 0) {
      items.forEach(item => {
        item.isAdded = false;
        this.searchItemResult.push(item);
      })
    }

    this.isSearchItemResultVisible = true;
    console.log("this.searchItemResult", this.searchItemResult);
  }

  searchMaterial() {
    switch (this.materialTypeId) {
      case '1': {
        forkJoin({
          coformulants: this.formulationService.getCoformulantsByName(this.addItemSearchText),
          fieldInerts: this.formulationService.getFieldInertsByName(this.addItemSearchText)
        })
          .subscribe(({ coformulants, fieldInerts }) => {
            const combinedResponse = [
              ...(coformulants || []),
              ...(fieldInerts || [])
            ];
            this.updateSearchItemResult(combinedResponse);
          });
        break;
      }
      case '2': {
        let formulationId = this.addFormulationValidationAiC + "-" + this.addFormulationValidationConcept + "-" + this.addFormulationValidationComposition;

        this.formulationService.getFormulationsByFormulationId(formulationId).subscribe(
          (response) => {
            this.updateSearchItemResult(response);
          }
        );
        break;
      }
      case '3': {
        this.formulationService.getCompoundbyMaterialId(this.addItemSearchText).subscribe(
          (response) => {
            this.updateSearchItemResult(response);
          }
        );
        break;
      }
      case '4': {
        this.formulationService.getBionexusMaterialbyId(this.addItemSearchText).subscribe(
          (response) => {
            this.updateSearchItemResult(response);
          }
        );
        break;
      }
    }
  }

  addIndividualItem(item) {
    item.isAdded = true;

    const newItem = {
      "materialId": item.materialIdInTargetSystem,
      "materialName": item.materialName,
      "isFormulation": false,
      "isGLP": {
        "label": "No",
        "value": false
      },
      "isGLPValidated": false,
      "isGLPColumnEnabled": item.sourceSystem === "FACTS" || item.sourceSystem === "CORE",
      "lotNumber": null,
      "weigthPercentage": 0,
      "quantity": 0,
      "expiredAcceptable": {
        "label": "Yes",
        "value": true
      },
      "reachApproved": {
        "label": "No",
        "value": false
      },
      "availability": true,
      "repo": item.sourceSystem,
    }

    if (item.sourceSystem == "FACTS") {
      newItem.isFormulation = true;
    }

    this.rawMaterials = [...this.rawMaterials, newItem];
  }

  removeIndividualItem(item) {
    item.isAdded = false;

    this.rawMaterials = this.rawMaterials.filter(material => material.materialId !== item.materialIdInTargetSystem);
  }

  isSelectedRawMaterialsValid() {
    this.updateSelectedRawMaterials();

    if (this.selectedRawMaterials.length === 0) {
      return false;
    }

    for (const selectedRawMaterial of this.selectedRawMaterials) {
      if (selectedRawMaterial.quantity <= 0) {
        return false;
      }
    }

    return true;
  }

  isSubmitDisabled(): boolean {
    return !this.isShippingAddressSaved ||
      !this.isAllSelectedGLPValid ||
      !this.isSelectedRawMaterialsValid();
  }

  handleInventoryCheckClick(rawMaterial: any) {
    if (this.inventoryCheck) {
      if (rawMaterial.selectedRawMaterial && rawMaterial.selectedRawMaterial.materialId) {
        this.inventoryCheck.getMaterialDetails(rawMaterial.repo, rawMaterial.selectedRawMaterial.materialId, 'mg', rawMaterial.selectedRawMaterial.materialName);
      } else {
        this.inventoryCheck.getMaterialDetails(rawMaterial.repo, rawMaterial.materialId, 'mg', rawMaterial.materialName);
      }
    }
  }
}
