<p-toast [style]="{marginTop: '80px'}"></p-toast>
<p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
<div class="grid">
  <div class="col-10">
      <h4 [ngClass]="getTitleStyle(forecastItem)">{{forecastTitle}} - {{forecastItem.stateName}}</h4>
  </div>
  <div class="col-2" *ngIf="showBackButton"  >
    <a (click)="goSearch()" [ngClass]="{'anchorDisabled': disableMaterialSearch}" style="float:right;"><span [title]="backButtonText" style="text-decoration: underline; cursor: pointer; float: right;"><i class="fa fas fa-arrow-left" aria-hidden="true" ></i>   {{backButtonText}}</span></a>
  </div>
</div>

<form [formGroup]="forecastForm" id="formForecastItemForm" (ngSubmit)="onSubmit(forecastForm.value)">
  <div *ngIf="showSearch" style="margin-top:-1.5rem">
    <app-composite-search [isGlpEligible]="user.isGlpeligible" (markDuplicates)="markDuplications($event)" (AddCartItem)="addCartItem($event)" (ShowMessage)="ShowMessage($event)"></app-composite-search>
  </div>
  <p-panel *ngIf="!showSearch">
    <p-header style="width: 100%;height: 108%;padding-top:15px;background-color: lightgray">
      <div class="grid" style="width: 100%;">
        <div class="col-3" style="padding-bottom: 10px" tooltipPosition="bottom"
        pTooltip="Checking this box bypasses the inventory reservation functionality.
           Your forecasted material will be available in the GSS inventory for any CPSS user to submit a sample request for.">
          <p-checkbox id="chkBypassInventory"
          [disabled]="forecastItem.stateName == 'Submitted' || forecastItem.stateName == 'In Process' || forecastItem.stateName == 'Cancelled' || forecastItem.stateName == 'Completed'"
          (onChange)="setInventory($event)" formControlName="bypassInventory"  style="padding-left: 18px;" name="chkBypassInventory"
          binary="true"></p-checkbox>
        <label style="padding-left: 3px;margin-top: 3px;" for="chkBypassInventory">Bypass Inventory Reservation</label>
        </div>
        <div class="col-3"></div>
        <div class="col-3" style="padding-left:25px;color: #333333;" >Material Planner:<span>&nbsp;<a style="color:#333333 !important; text-decoration: underline !important;"  (click)="$event.stopPropagation();" title="{{forecastItem.materialPlannerEmail}}" href="mailto:{{forecastItem.materialPlannerEmail}}">{{forecastItem.materialPlannerName}}</a></span>
        </div>
        <div *ngIf="forecastItem.approverEmail!==null" class="col-3">
          <span style="float:right;">
            <span *ngIf="forecastItem.isApproved||(forecastItem.forecastingUserEmail===forecastItem.approverEmail)"><i style="color: green;" class="pi pi-check-circle" [title]="'Approved'" id="{{'IsApprovedYes' + forecastItem.id}}"></i>&nbsp;</span>
            <span *ngIf="forecastItem.isApproved===false&&(forecastItem.forecastingUserEmail!=forecastItem.approverEmail)"><i style="color: red;" class="pi pi-times-circle" [title]="'Rejected'" id="{{'IsApprovedNo' + forecastItem.id}}"></i>&nbsp;</span>
            <a style="text-decoration: underline;" href="mailto:{{forecastItem.approverEmail}}">{{forecastItem.approverName ?? forecastItem.approverEmail}}</a>
            <span>&nbsp;<span *ngIf="forecastItem.isApproved||(forecastItem.forecastingUserEmail===forecastItem.approverEmail)">approved the forecast</span><span *ngIf="forecastItem.isApproved===false&&(forecastItem.forecastingUserEmail!=forecastItem.approverEmail)">rejected the forecast</span><span *ngIf="forecastItem.isApproved===null&&(forecastItem.forecastingUserEmail!=forecastItem.approverEmail)">is the approver</span></span>
          </span>
        </div>
      </div>
    </p-header>
    <div class="form-group row">
      <div class="col-md-2"><label style="font-weight: bold;">*Material Name:</label></div>
      <div class="col-md-4">
        <input type="text" id="txtForecastItemMaterialName" disabled="disabled" formControlName="materialName" pInputText class="ui-inputtext">
        <p-message severity="error" text="Material Name is required"
        *ngIf="!forecastForm.controls['materialName'].valid&&forecastForm.controls['materialName'].dirty"></p-message>
        <a (click)="goSearch()" [ngClass]="{'anchorDisabled': disableMaterialSearch}">
          <span><i class="fa fa-search fa-2x" style="color:#0072ce;padding-left:3px;" aria-hidden="true"></i>
          </span></a>
        <i class="fa fa-info-circle fa-2x" title="{{GetTitle()}}" style="padding-left:3px;" aria-hidden="true" (click)="forecastItem?.materialId && showAIDialog(true)"></i>
      </div>
      <div class="col-md-2" style="font-weight: bold;"><label>*Forecast Type:</label></div>
      <div class="col-md-4">
        <p-dropdown formControlName="forecastType" id="ddrforecastitemForecastType" [options]="forecastTypes"
        optionLabel="forecastType1" placeholder="Forecast type" [disabled]="!user.isGlpeligible||forecastItem.stateName=='Submitted'||forecastItem.stateName=='In Process'||forecastItem.stateName=='Cancelled'||forecastItem.stateName=='Completed'"
        ></p-dropdown>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-6">
        <div class="form-group row">
          <div class="col-md-4"><label>Synonyms:</label></div>
          <div class="col-md-8">
            <input type="text" id="txtForecastItemMaterialSynonyms" disabled="disabled" [title]="forecastItem.synonyms !== null ? forecastItem.synonyms.replaceAll('||', '; ') : ''" formControlName="materialNameSynonyms" pInputText class="ui-inputtext">
          </div>
        </div>
      </div>
      <div class="col-md-2"><label>Total Forecast Quantity:</label></div>
      <div class="col-md-4"><label>{{totalForecast}}</label></div>
      <!-- <div class="col-md-2" *ngIf="!isFormulationSpecialist && !isCommercialUser" ><label style="font-weight: bold;">*Forecast Season:</label></div>
      <div class="col-md-4" *ngIf="!isFormulationSpecialist && !isCommercialUser">
        {{bypassInventory}}
        <p-dropdown [options]="forecastingSeasons" optionLabel="name" [disabled]="isBypassInventory"  id="ddrForecastItemForecastingSeasons"
        id="FSeason" name="FSeason" formControlName="forecastSeason"></p-dropdown>
      </div> -->
    </div>
    <div class="form-group row">
      <div class="col-md-6">
        <div class="form-group row">
          <div class="col-md-4"><label>Alternative Product:</label></div>
        <div class="col-md-8">
          <input type="text" id="txtForecastItemAlternativeProduct" disabled="disabled"  formControlName="alternativeProduct" pInputText class="ui-inputtext">
  <span style="position:relative; right: 1em; line-height: 40px; color:#8b9094;
  cursor: pointer; font-size: 25px; font-weight: bold;" (click)="ClearAlternativeProduct($event)">&times;</span>
          <a (click)="goSearchAlternative()" [ngClass]="{'anchorDisabled': disableMaterialSearch}">
            <span><i class="fa fa-search fa-2x" style="color:#0072ce;padding-left:3px;" aria-hidden="true"></i>
            </span></a>
            <i class="fa fa-info-circle fa-2x" title="Click Here to View Alternative Product" style="padding-left:3px;" aria-hidden="true" (click)="forecastItem?.altMaterialId && OpenAlternativeProductPopup()"></i>
        </div>
        </div>
        <div class="form-group row">
          <div class="col-md-4"><label>Reach Approved:</label></div>
          <div class="col-md-8">
            <p-inputSwitch formControlName="isReachApproved" id="inputSwitchForecastItemIsReachApproved" (onChange)="onUomChange()" ></p-inputSwitch>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-4"><label>Formulation Type:</label></div>
          <div class="col-md-8">
            <input type="text" id="txtForecastFormulationType" formControlName="formulationType" disabled="disabled" pInputText class="ui-inputtext">
          </div>
        </div>
        <div class="form-group row" >
          <div class="col-md-4"><label [hidden]="!isBiologyLeader">Delegator:</label></div>
          <div class="col-md-8">
            <p-dropdown [options]="delegators" optionLabel="secondaryEmail" id="ddrForecastItemDelegator"
            formControlName="delegate" [hidden]="!isBiologyLeader" [disabled]="'disabled'"
            placeholder="Select delegator"  [showClear]="true"
            ></p-dropdown>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-4"><label>Forecaster:</label></div>
          <div class="col-md-8">
            <input type="text" disabled="disabled" id="txtForecaster" formControlName="forecaster" pInputText class="ui-inputtext">
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-4">
            <label style="font-weight: bold;" *ngIf="!isFormulationSpecialist && !isCommercialUser && !isBiologyLeader">*Reviewer:</label>
            <label   *ngIf="isBiologyLeader">Reviewer:</label>
          </div>
          <div class="col-md-8">
            <p-autoComplete  id="autoForecastItemReviewer"  [suggestions]="filteredReviewers"
            (completeMethod)="filterReviewers($event)" (onSelect)="reviewerSeleted($event)"
            (onClear)="reviewerRemoved()"  *ngIf="!isFormulationSpecialist && !isCommercialUser"
            field="secondaryEmail" [size]="20"  formControlName="reviewer"
            placeholder="Select reviewer" [minLength]="1"  [type]="'search'"
            [disabled]="editForecastItem != null &&
            (user.id != editForecastItem.forecastingUserId || editForecastItem.delegatorUserId != null
             || editForecastItem.stateId == submittedStateId || editForecastItem.stateId == materialApprovalStateId)"
            >
          </p-autoComplete>
          </div>
        </div>
    </div>
    <div class="col-md-6">
      <div class="form-group row" >
        <div class="col-md-12" style="background-color: #007ad9 !important;color: white">
          <div class="pull-left" style="padding-top: 3px;">Comment</div>
          <div class="pull-right"><button (click)="openCommentDialog();" pButton type="button"style="padding: 0 0.75rem;margin-top:0px !important;border:none;" class="w-full" label="Add Comments" icon="fa fa-plus" iconPos="right"></button></div>
        </div>
        <div class="col-md-12" *ngIf="forecastComments" style="height: 200px;max-height: 300px; overflow: auto;border: #0072ce;border: 1px solid grey;padding-right: 0px;padding-left: 0px;">
          <p-dataView #dv [value]="forecastComments" id="forecastCommentsView"
            [lazy]="true" [totalRecords]="totalCount" layout="list" styleClass="hover">
            <ng-template pTemplate="header">
              <div class="row" style="width: 100%;color:black;">
                <div class="col-2 header-column">
                  <label style="font-weight: bold;">Date</label>
                </div>
                <div class="col-2 header-column">
                  <label style="font-weight: bold;padding-left: 5px">Name</label>
                </div>
                <div class="col-7 header-column">
                  <label style="font-weight: bold;padding-left: 12px">Comment</label>
                </div>
              </div>
            </ng-template>
            <ng-template let-forecastComment pTemplate="listItem" let-i="rowIndex">
              <div class="form-group row"
                [ngStyle]="{'width': '100%','min-height':'40px','margin-left': '0px','margin-bottom':'5px','background-color':(i%2==0 ? 'white' : 'lightgray'),'color':(i%2==0 ? 'black' : 'black')}">
                <div class="col-2 col-value">
                  <div>{{commonService.convertUTCToLocalTime(forecastComment.dateEntered) | date: 'dd-MMM-yyyy' }}</div>
                </div>
                <div class="col-2 col-value">
                  <div>
                    <a href="mailto:{{forecastComment.user.email}}">{{forecastComment.user.firstName}} {{forecastComment.user.lastName}}</a>
                  </div>
                </div>
                <div class="col-7 col-value">
                  <div>{{forecastComment.comment}}</div>
                </div>
              </div>
            </ng-template>
          </p-dataView>
        </div>
      </div>
    </div>
    </div>
    <!-- <div class="form-group row" >
      <div class="col-md-6">
        
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-6">
      </div>
    </div> -->
      <div class="form-group row">
        <div class="col-md-8"></div>
        <div class="col-md-4">
         <button pButton type="button" id="btnForecastItemDelegate" *ngIf="isBiologyLeader && forecastItem?.id > 0 && forecastItem?.stateId == dataEntryStateId" label="Delegate" (click)="getSeasonDelegators()" style="margin-right: 10px;"></button>
         <button pButton type="submit" id="btnForecastItemCustom" [label]="buttonName" [disabled]="disableSubmitBtn(forecastForm.valid)"
          (onClick)="onSubmit($event)" style="margin-right: 10px;"></button>
         <button pButton type="button" label="Save" id="btnForecastItemSave" [disabled]="disableSaveBtn(forecastForm.valid)" (click)="onSave($event)" style="margin-right: 10px;"></button>
         <button pButton type="button" label="Cancel" (click)="onReset($event)" id="btnForecastItemCancel"  style="margin-right: 10px;"></button>
        </div>
      </div>
    <div>
       <app-forecast-location
        [ForecastItem]="forecastItem"
        [ForecastDestinations]="forecastDestinations"
        [UserInfo]="user"
        [disableActionButtons]="!forecastForm.valid || !isModify"
        [quantUoms]="quantUoms"
        [forecastStatus]="forecastItem.stateName"
        [isForecastCopy] ="isForecastCopy"
        [isBypassInventory] ="isBypassInventory"
        [showDateNeededRange]="isFormulationSpecialist || isCommercialUser"
        [selectedquantUomId]="forecastItem.quantityUnitOfMeasureId"
        (AddDestination)="AddDestination($event)"
        (UpdateDestination)="UpdateDestination($event)"
        (RemoveDestination)="RemoveDestination($event)"></app-forecast-location>
    </div>
  </p-panel>
</form>
<p-dialog header="{{GetAltDialogTitle()}}" [(visible)]="alternativeProductPopup" id="dialogalternativeProductActiveIngredients" [modal]="true"
  [style.z-index]="1000"   [styleClass]="'clsDialogFormulationActiveIngredientStyle'"
  [style.minWidth]="'725px'" [style.minHeight]="'475px'"   [style.maxWidth]="'725px'"
  [draggable]="false" [resizable]="false">

  <div class="grid leftMargin"  *ngIf="altMaterialType == 'Coformulant/Field Inert'">
    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;">
      <span style="font-weight: bold;">Trade Name:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem !important;" *ngIf="forecastItem?.acceptableAlternativeProduct != null">
      {{forecastItem?.acceptableAlternativeProduct}}
    </li>
    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;">
      <span style="font-weight: bold;">Common Name:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem !important;" *ngIf="forecastItem?.altMaterialSynonyms != null && forecastItem?.altMaterialSynonyms.length >0">
      {{forecastItem?.altMaterialSynonyms.split('||').join('; ')}}
   </li>

</div>
  <div   class="grid" *ngIf="altMaterialType == 'Formulation Lot' || altMaterialType == 'Formulation'" >
    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;">
      <span style="font-weight: bold;">Material Name:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem !important;">
      {{forecastItem.acceptableAlternativeProduct}}
    </li>
    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;" *ngIf="altMaterialType == 'Formulation Lot'">
      <span style="font-weight: bold;">Lot Number:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem !important;" *ngIf="altMaterialType == 'Formulation Lot' && forecastItem.altLotId != null">
      {{forecastItem.altLotId }}
    </li>
    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;">
      <span style="font-weight: bold;">Formulation Type:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem !important;">
      {{forecastItem.altMaterialFormulationType}}
    </li>
    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;" *ngIf="altMaterialType == 'Formulation Lot'">
      <span style="font-weight: bold;">Re-Certification Date:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem !important;" *ngIf="altMaterialType == 'Formulation Lot' && forecastItem.altMaterialRecertDate != null">
      {{forecastItem.altMaterialRecertDate | date: 'dd-MMM-yyyy' }}
    </li>
    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;" *ngIf="altMaterialType == 'Formulation Lot' && showField">
      <span style="font-weight: bold;">TSN:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem !important;" *ngIf="altMaterialType == 'Formulation Lot' && forecastItem.altMaterialTsnNumber != null && showField">
      {{forecastItem.altMaterialTsnNumber}}
    </li>
    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;"  *ngIf="altMaterialType == 'Formulation'">
      <span style="font-weight: bold;">Actives:</span>
    </div>
    <div style="width: 100%;" *ngIf="altMaterialType == 'Formulation'">
    <div class="col-12" style="padding-bottom: 0px;" *ngFor="let active of forecastItem.altForecastItemActiveIngredient">

      <div class="grid"  >
        <div class="col-8 clsSpaceActives">
          <li class='col-12 clsSpacing' style="padding-top: 0rem !important;" >
            {{active.activeIngredient}}
          </li>
         </div>
        <div class="col-2 clsSpaceActives">{{active.concentrationInPrimaryUom}}</div>
        <div class="col-2 clsSpaceActives">{{active.concentrationInSecondaryUom}}</div>
      </div>
    </div>
  </div>

    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;"  >
      <span style="font-weight: bold;">Synonyms:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem !important;" *ngIf="forecastItem?.altMaterialSynonyms != null && forecastItem?.altMaterialSynonyms.length >0">
      {{forecastItem?.altMaterialSynonyms.split('||').join('; ')}}
   </li>

  </div>
  
  <div class="grid" *ngIf="altMaterialType === 'Material'" >
    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;">
      <span style="font-weight: bold;">Material Name:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem !important;">
      {{forecastItem.acceptableAlternativeProduct}}
    </li>
    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;" *ngIf="forecastItem.altLotNumber == null">
      <span style="font-weight: bold;">Material Id:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem !important;" *ngIf="forecastItem.altLotNumber == null" >
      {{forecastItem.altMaterialId }}
    </li>
    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;" *ngIf="forecastItem.altLotNumber">
      <span style="font-weight: bold;">Lot Id:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem !important;" *ngIf="forecastItem.altLotNumber" >
      {{forecastItem.altLotNumber }}
    </li>
    <div class="col-12">
      
    <p-tabView style="border: 0px !important;padding: 0 !important;">
      <p-tabPanel header="Material Attributes" headerStyleClass="tabpanelClass" style="display: block; max-height: 175px;overflow-y: auto;" >
        <div style="min-height: 175px;">
        <p-table [columns]="cols" [value]="forecastItem.altMaterialAttributes"  >
          <!-- <ng-template pTemplate="header" let-columns> 
            <tr>
              <th>Name</th>
              <th>Value</th>
          </tr>
          </ng-template> -->
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td style="padding: 0px;">
                <span>{{ rowData['name'] }}</span>
              </td>
              <td style="padding: 0px;">
                <span>{{ rowData['values'].join('; ') }}</span>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      </p-tabPanel>
      <p-tabPanel header="Lot Attributes"  style="display: block; max-height: 175px;overflow-y: auto;" *ngIf="forecastItem.altLotAttributes">
        <div style="min-height: 175px;">
        <p-table [columns]="cols" [value]="forecastItem.altLotAttributes" >
          
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td style="padding: 0px;">
                <span>{{ rowData['name'] }}</span>
              </td>
              <td style="padding: 0px;">
                <span>{{ rowData['values'].join(';') }}</span>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      </p-tabPanel>
        <p-tabPanel header="Synonyms" headerStyleClass="tabpanelClass" style="display: block; max-height: 175px;overflow-y: auto;">
          <div style="min-height: 175px;">
            <p-table [value]="forecastItem?.altMaterialSynonyms?.split('||')">
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                  <td style="padding: 0px;">
                    <span>{{ rowData }}</span>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </p-tabPanel>
    </p-tabView>
    </div>
  </div>

  <div class="grid" *ngIf="altMaterialType == 'Compound'">
    <div class='col-12' style="padding-bottom: 0rem !important ;">
      <span style="font-weight: bold;">Core Material ID:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem; padding-bottom: 0rem !important;">
      {{forecastItem?.altMaterialId }}
    </li>
    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;">
      <span style="font-weight: bold;">Synonyms:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem !important;" *ngIf="forecastItem?.altMaterialSynonyms != null && forecastItem?.altMaterialSynonyms.length >0">
       {{forecastItem?.altMaterialSynonyms.split('||').join('; ')}}
    </li>
  </div>


  <div class="grid" *ngIf="altMaterialType == 'Compound Lot'">
    <div class='col-12' style="padding-bottom: 0rem !important ;">
      <span style="font-weight: bold;">Core Material ID:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem; padding-bottom: 0rem !important;">
      {{forecastItem?.altMaterialId}}
    </li>
    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;">
      <span style="font-weight: bold;">Lot ID:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem; padding-bottom: 0rem !important;" *ngIf="forecastItem.altLotId != null">
      {{forecastItem.altLotId}}
    </li>
    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;" *ngIf="showField">
      <span style="font-weight: bold;">TSN:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem; padding-bottom: 0rem !important;" *ngIf="forecastItem.altMaterialTsnNumber != null && showField">
      {{forecastItem.altMaterialTsnNumber}}
    </li>
     <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;" *ngIf="showField">
      <span style="font-weight: bold;">Purity:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem; padding-bottom: 0rem !important;" *ngIf="forecastItem.altMaterialPurity != null && showField">
      {{forecastItem.altMaterialPurity}}
    </li>
    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;" >
      <span style="font-weight: bold;">Re-Certification Date:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem; padding-bottom: 0rem !important;" *ngIf="forecastItem.altMaterialRecertDate != null">
      {{forecastItem.altMaterialRecertDate | date: 'dd-MMM-yyyy' }}
    </li>
    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;">
      <span style="font-weight: bold;">Synonyms:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem !important;" *ngIf="forecastItem?.altMaterialSynonyms != null && forecastItem?.altMaterialSynonyms.length >0">
      {{forecastItem?.altMaterialSynonyms.split('||').join('; ')}}
   </li>
</div>
<div class="col-12">
    <button pButton type="button" label="Done" id="btnForecastItemForemulationAIDone" style="float: right;"
      (click)="alternativeProductPopup=false;"></button>
  </div>
</p-dialog>
<p-dialog header="{{GetDialogTitle()}}" [(visible)]="isShowAIDialog" id="dialogForemulationActiveIngredients" [modal]="true"
  [style.z-index]="1000"   [styleClass]="'clsDialogFormulationActiveIngredientStyle'"
  [style.minWidth]="'725px'" [style.minHeight]="'475px'"   [style.maxWidth]="'725px'"
  [draggable]="false" [resizable]="false">

  <div class="grid leftMargin"  *ngIf="materialType == 'Coformulant/Field Inert'">

    <div class='col-12'>
      <span style="font-weight: bold;">Trade Name:</span>
    </div>

    <li class='col-12 clsSpacing' style="padding-top: 0rem !important;" *ngIf="forecastItem?.productName != null && forecastItem?.productName.length >0">
      {{forecastItem?.productName}}
    </li>
    <div class='col-12'>
      <span style="font-weight: bold;">Common Name:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem !important;" *ngIf="forecastItem?.synonyms != null && forecastItem?.synonyms.length >0">
       {{forecastItem?.synonyms.split('||').join('; ')}}
    </li>

</div>
<div class="grid" *ngIf="materialType === 'Material'" >
  <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;">
    <span style="font-weight: bold;">Material Name:</span>
  </div>
  <li class='col-12 clsSpacing' style="padding-top: 0rem !important;">
    {{forecastItem.productName}}
  </li>
  <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;" *ngIf="forecastItem.lotNumber==null">
    <span style="font-weight: bold;">Material Id:</span>
  </div>
  <li class='col-12 clsSpacing' style="padding-top: 0rem !important;"  *ngIf="forecastItem.lotNumber==null">
    {{forecastItem.materialId }}
  </li>
  <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;" *ngIf="forecastItem.lotNumber">
    <span style="font-weight: bold;">Lot Id:</span>
  </div>
  <li class='col-12 clsSpacing' style="padding-top: 0rem !important;"  *ngIf="forecastItem.lotNumber">
    {{forecastItem.lotNumber }}
  </li>
  <div class="col-12">
    
  <p-tabView style="border: 0px !important;padding: 0 !important;">
    <p-tabPanel header="Material Attributes" headerStyleClass="tabpanelClass" style="display: block; max-height: 175px;overflow-y: auto;" >
      <div style="min-height: 175px;">
      <p-table [columns]="cols" [value]="forecastItem.materialAttributes"  >
        <!-- <ng-template pTemplate="header" let-columns> 
          <tr>
            <th>Name</th>
            <th>Value</th>
        </tr>
        </ng-template> -->
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td style="padding: 0px;">
              <span>{{ rowData['name'] }}</span>
            </td>
            <td style="padding: 0px;">
              <span>{{ rowData['values'].join('; ') }}</span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    </p-tabPanel>
    <p-tabPanel header="Lot Attributes"  style="display: block; max-height: 175px;overflow-y: auto;" *ngIf="forecastItem.lotAttributes">
      <div style="min-height: 175px;">
      <p-table [columns]="cols" [value]="forecastItem.lotAttributes" >
        
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td style="padding: 0px;">
              <span>{{ rowData['name'] }}</span>
            </td>
            <td style="padding: 0px;">
              <span>{{ rowData['values'].join(';') }}</span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    </p-tabPanel>
      <p-tabPanel header="Synonyms" headerStyleClass="tabpanelClass" style="display: block; max-height: 175px;overflow-y: auto;">
        <div style="min-height: 175px;">
          <p-table [value]="forecastItem?.synonyms?.split('||')">
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr>
                <td style="padding: 0px;">
                  <span>{{ rowData }}</span>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </p-tabPanel>
  </p-tabView>
  </div>
</div>
  <div   class="grid" *ngIf="materialType == 'Formulation Lot' || materialType == 'Formulation'" >
    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;">
      <span style="font-weight: bold;">Material Name:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem !important;">
      {{forecastItem.productName}}
    </li>
    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;" *ngIf="materialType == 'Formulation Lot'">
      <span style="font-weight: bold;">Lot Number:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem !important;" *ngIf="materialType == 'Formulation Lot' && forecastItem.lotNumber != null">
      {{forecastItem.lotNumber }}
    </li>
    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;">
      <span style="font-weight: bold;">Formulation Type:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem !important;">
      {{forecastItem.formulationType}}
    </li>
    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;" *ngIf="materialType == 'Formulation Lot'">
      <span style="font-weight: bold;">Re-Certification Date:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem !important;" *ngIf="materialType == 'Formulation Lot' && forecastItem.recertDate != null">
      {{forecastItem.recertDate | date: 'dd-MMM-yyyy' }}
    </li>
    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;" *ngIf="materialType == 'Formulation Lot' && showField">
      <span style="font-weight: bold;">TSN:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem !important;" *ngIf="materialType == 'Formulation Lot' && forecastItem.tsnNumber != null && showField">
      {{forecastItem.tsnNumber}}
    </li>
    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;"  *ngIf="materialType == 'Formulation'">
      <span style="font-weight: bold;">Actives:</span>
    </div>
    <div style="width: 100%;" *ngIf="materialType == 'Formulation'">
    <div class="col-12" style="padding-bottom: 0px;" *ngFor="let active of forecastItem.forecastItemActiveIngredient">

      <div class="grid"  >
        <div class="col-8 clsSpaceActives">
          <li class='col-12 clsSpacing' style="padding-top: 0rem !important;" >
            {{active.activeIngredient}}
          </li>
         </div>
        <div class="col-2 clsSpaceActives">{{active.concentrationInPrimaryUom}}</div>
        <div class="col-2 clsSpaceActives">{{active.concentrationInSecondaryUom}}</div>

      </div>

    </div>
  </div>

    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;"  >
      <span style="font-weight: bold;">Synonyms:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem !important;" *ngIf="forecastItem?.synonyms != null && forecastItem?.synonyms.length >0">
      {{forecastItem?.synonyms.split('||').join('; ')}}
   </li>


  </div>
  <div class="grid" *ngIf="materialType == 'Compound'">
    <div class='col-12' style="padding-bottom: 0rem !important ;">
      <span style="font-weight: bold;">Core Material ID:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem; padding-bottom: 0rem !important;">
      {{forecastItem?.materialId}}
    </li>
    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;">
      <span style="font-weight: bold;">Synonyms:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem !important;" *ngIf="forecastItem?.synonyms != null && forecastItem?.synonyms.length >0">
      {{forecastItem?.synonyms.split('||').join('; ')}}
   </li>

</div>
  <div class="grid" *ngIf="materialType == 'Compound Lot'">
    <div class='col-12' style="padding-bottom: 0rem !important ;">
      <span style="font-weight: bold;">Core Material ID:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem; padding-bottom: 0rem !important;">
      {{forecastItem?.materialId}}
    </li>
    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;">
      <span style="font-weight: bold;">Lot ID:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem; padding-bottom: 0rem !important;" *ngIf="forecastItem.lotId != null">
      {{forecastItem.lotId}}
    </li>
    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;" *ngIf="showField">
      <span style="font-weight: bold;">TSN:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem; padding-bottom: 0rem !important;" *ngIf="forecastItem.tsnNumber != null && showField">
      {{forecastItem.tsnNumber}}
    </li>
    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;" *ngIf="showField">
      <span style="font-weight: bold;">Purity:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem; padding-bottom: 0rem !important;" *ngIf="forecastItem.Purity != null && showField">
      {{forecastItem.Purity}}
    </li>
    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;" >
      <span style="font-weight: bold;">Re-Certification Date:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem; padding-bottom: 0rem !important;" *ngIf="forecastItem.recertDate != null">
      {{forecastItem.recertDate | date: 'dd-MMM-yyyy' }}
    </li>
    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;">
      <span style="font-weight: bold;">Synonyms:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem !important;" *ngIf="forecastItem?.synonyms != null && forecastItem?.synonyms.length >0">
      {{forecastItem?.synonyms.split('||').join('; ')}}
   </li>
</div>
<div class="col-12">
    <button pButton type="button" label="Done" id="btnForecastItemForemulationAIDone" style="float: right;"
      (click)="isShowAIDialog=false;"></button>
  </div>
</p-dialog>



<p-dialog header="Delegate Forecast" [(visible)]="isShowDelegateDialog" [modal]="false"
[style]="{'min-width':'450px'}" [style.z-index]="1000"
  [draggable]="true" [resizable]="false">
  <form>
  <div class="form-group row">
    <div class="col-md-12" *ngIf="seasionDelegators?.length > 0" ><label>Who would you like to delegate this forecast to?</label></div>
    <div class="col-md-12" *ngIf="seasionDelegators?.length == 0" ><label>No delegates in the forecast season</label></div>
  </div>
  <div class="form-group row">
    <div class="col-md-12" style="padding-left: 1em;padding-right: 2em;">
  <div class="form-group row" *ngIf="seasionDelegators?.length > 0"   >
    <div class="col-md-1"> <p-checkbox name="groupname" id="chkForecastItemSelectAllSeaionDelegator"  (onChange)="selectAll()"  [(ngModel)]="SelectAll" [binary]="true"  ></p-checkbox></div>
    <div class="col-md-11"> <span      >Select All</span></div>
  </div>
  <div class="form-group row"  *ngIf="seasionDelegators?.length > 0">
    <div class="col-md-12" style="height: 108px;overflow:auto;" >
  <div class="form-group row" *ngFor="let user of seasionDelegators;let i = index"  >
    <div class="col-md-1"> <p-checkbox name="{{user.fullName}}"  id="{{ 'chkForecastItem' + i }}"  [(ngModel)]="user.selected" [binary]="true"  (onChange)="onCheck()" ></p-checkbox> </div>
    <div class="col-md-11"> <span      >{{user.fullName}}</span> </div>
  </div>
</div>
</div>
</div>
</div>
  <div class="form-group row">
    <div class="col-md-6">
      <button pButton type="button" label="Accept"  id="btnForecastItemcreateDelegateForecast" [disabled]="!enableAccept" style="float: right;"
          (click)="createDelegateForecast()"
           ></button>
    </div>
    <div class="col-md-6"> <button pButton type="button" id="btnForecastItemCancelDelegateForecast" label="Cancel"  style="float: left;"
      (click)="isShowDelegateDialog=false;"></button> </div>
  </div>
</form>
</p-dialog>

<p-dialog id="dialogAcceptComment" header='New Comment' [(visible)]="dialogAcceptCommentDialog"
[styleClass]="'clsDialog'"
 [modal]="true"
  [dismissableMask]="true">
  <div class="form-group row">
    <div class="col-12">
      <textarea name="commentText" [value]="commentText" (input)="commentText = $event.target.value;" style="height: 200px;overflow-y: scroll;border-width: 1px;;" formControlName="commentText" id="txtComment" [rows]="10" [cols]="50"></textarea>
    </div>
  </div>
  <div class="form-group row">
    <div class="col-md-12">
      <button type="button" pButton id="btnSaveComment" [disabled]= "commentText == ''"  (click)="onComment()" label="Save"   ></button>
      <button style="margin-left: 10px;" type="button" pButton id="btnCancel" (click)="dialogAcceptCommentDialog=false;" label="Cancel"   ></button>
    </div>
  </div>
</p-dialog>
<p-dialog id="dialogAcceptComment" header='Bypass Reservation' [(visible)]="submitForProcurementDialog"
[styleClass]="'clsDialog'"
 [modal]="true"
  [dismissableMask]="true">
  <div class="form-group row">
    <div class="col-md-1">
      <i class='fa fa-3x fa-question-circle' style="margin-top: 5px;color: #007ad9 !important;"></i>
    </div>
    <div class="col-md-11">
      <p style="margin-top: 15px;">Are you sure you want to submit a forecast that doesn't have a reservation?</p>
    </div>
  </div>
  <div class="form-group row">
    <div class="col-md-8">
    </div>
    <div class="col-md-4">
      <button type="button" style="margin-right: 5px;" pButton id="btnNo" icon="pi pi-times" (click)="submitForProcurementDialog=false;" label="No"></button>
      <button type="button" pButton icon="pi pi-check" id="btnYes" (click)="processCreateForecastItem()" label="Yes"></button>
    </div>
  </div>
</p-dialog>

<p-dialog id="dialogReviewerChange" header='Reviewer' [(visible)]="reviewerChangeDialog"
[styleClass]="'clsDialogReviewerChangeStyle'"
 [modal]="true"
  [dismissableMask]="true" [style.maxWidth]="'471px'" >
  <div class="form-group row">
    <div class="col-md-1">
      <i class='fa fa-3x fa-question-circle' style="margin-top: 17px;color: #007ad9 !important;"></i>
    </div>
    <div class="col-md-11">
      <p style="margin-top: 15px;">The original reviewer could still be reviewing at this time. <br>Are you sure you want change the reviewer?</p>
    </div>
  </div>
  <div class="form-group row">
    <div class="col-md-8">
    </div>
    <div class="col-md-4">
      <button type="button"  style="margin-right: 5px;" pButton icon="pi pi-check" id="btnYes" (click)="UpdateReviewer()" label="Yes"></button>
      <button type="button" pButton id="btnNo" icon="pi pi-times" (click)="reviewerChangeDialog=false;isSaveButton=false" label="No"></button>

    </div>
  </div>
</p-dialog>
<p-dialog id="dialogNeedDateRevision" header='Need Date Revision' [(visible)]="needDateRevisionDialog"
[styleClass]="'dialogNeedDateRevisionChangeStyle'"
 [modal]="true"
  [dismissableMask]="true" (onHide)="dateRevisionAccepted($event)" [style.maxWidth]="'471px'" >
  <div class="form-group row">
    <div class="col-md-1">
      <i class='fa fa-3x fa-exclamation-circle' style="margin-top: 17px;color: #007ad9 !important;"></i>
    </div>
    <div class="col-md-11">
      <p style="margin-top: 15px;">
        Forecast(s) to {{needDateRevisionCountries}} did not meet the 45-day minimum period, date needed has been updated to {{ newforecastRevisionDate | date: 'dd-MMM-yyyy' }}</p>
    </div>
  </div>
  <div class="form-group" style="float:right;">
      <button type="button" style="margin-left: 25px;" pButton icon="pi pi-check" id="btnOk" (click)="dateRevisionAccepted($event)" label="Ok"></button>
  </div>
</p-dialog>
